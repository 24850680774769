//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {deleteAdmin, fetchAdmin} from "@/api/admin";
import {fetchBranch, fetchRole} from "@/api/master";
import {Message} from "element-ui";

export default {
	name: 'Users',
	created() {
		document.title = "Users : ONE Tracemoney"
	},
	data() {
		return {
			items: [],
			fields: [
				{key: 'name'},
				{key: 'username'},
				{key: 'role'},
				{key: 'branch'},
				{key: 'phone'},
				{key: 'email'},
				{key: 'status'},
				{
					key: 'show_details',
					label: '',
					_style: 'min-width: 100px',
					sorter: false,
					filter: false
				}
			],
			activePage: 1,
			options: [],
			roles: [{value: 0, label: "ทั้งหมด"}],
			searchParams: {
				keywords: '',
				branchId: 0,
				roleId: 0
			}
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			await new Promise((resolve, reject) => {
				fetchBranch().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.options = data
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
			await new Promise((resolve, reject) => {
				fetchRole().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						// this.roles = data;
						Array.prototype.push.apply(this.roles, data);
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
			await this.getUserData()
		} finally {
			this.$store.state.loading = false
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (route.query && route.query.page) {
					this.activePage = Number(route.query.page)
				}
			}
		}
	},
	methods: {
		getUserData() {
			fetchAdmin(this.searchParams).then(response => {
				this.items = []
				const check = response.header
				const data = response.body
				if (check.error === 'N') {
					for (let item of data) {
						if (item.status === 1)
							item.status = 'Active'
						else
							item.status = 'Inactive'
						this.items.push(item)
					}
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		async searchUser() {
			this.items = []
			await this.getUserData();
		},
		getBadge(status) {
			switch (status) {
				case 'Active':
					return 'success'
				case 'Inactive':
					return 'secondary'
				case 'Pending':
					return 'warning'
				case 'Banned':
					return 'danger'
				default:
					return 'primary'
			}
		},
		rowEdit(item) {
			this.$router.push({path: `users/detail/${item.userid}`})
		},
		rowDelete(item) {
			let params = {
				accountId: item.userid
			}
			deleteAdmin(params).then(response => {
				const check = response.header
				if (check.error === 'N') {
					Message({
						message: 'ลบผู้ใช้สำเร็จ ผู้ใช้นี้จะไม่สามารถใช้งานได้อีกต่อไป',
						type: 'success',
						duration: 5 * 1000,
					})
					this.getUserData()
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด ลบผู้ใช้ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		pageChange(val) {
			this.$router.push({query: {page: val}})
		}
	}
}
